import React from 'react'
import styles from './GroupHeader.module.css'

function GroupHeader({ workerGroup }) {
    return (
        <div className={styles.GroupHeader}>

            <div>
                <h3 className='bold'>{workerGroup.name}</h3>
                <p>{workerGroup.description}</p>
            </div>

        </div>
    )
}

export default GroupHeader