import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getExpenseGroup, getGroupExpenses } from '../api/expenses';
import AddExpenseModal from '../components/AddExpenseModal';
import GroupHeader from '../components/groupHeader/GroupHeader';
import EditExpenseModal from '../components/EditExpenseModal';
import DeleteExpenseModal from '../components/DeleteExpenseModal';
import { PencilSquare, Plus, TrashFill } from 'react-bootstrap-icons';
import { formatDate } from '../../common/utils/format_utils';

function ExpenseGroup() {

  const { group_id } = useParams();
  const [expenseGroup, setExpenseGroup] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [showDeleteExpenseModal, setShowDeleteExpenseModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchExpenseGroup = async () => {
      try {
        const fetchedExpenseGroup = await getExpenseGroup(group_id);
        setExpenseGroup(fetchedExpenseGroup);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    const fetchExpenses = async () => {
      try {
        const fetchedExpenses = await getGroupExpenses(group_id);
        setExpenses(fetchedExpenses);
      } catch (error) {
        console.error('Error fetching :', error);
      }
    };

    fetchExpenseGroup();
    fetchExpenses();
  }, []);

  const openAddExpenseModal = () => {
    setShowAddExpenseModal(true);
  };

  const openEditExpenseModal = (expense) => {
    setShowEditExpenseModal(true);
    setSelectedExpense(expense);
  }

  const openDeleteExpenseModal = (expenseGroup) => {
    setShowDeleteExpenseModal(true);
    setSelectedExpense(expenseGroup);
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
        <div className='spinner-border txt-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>

      <GroupHeader expenseGroup={expenseGroup} />

      <button onClick={() => { openAddExpenseModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
        <Plus className='h4 m-0' />
      </button>

      <div className='container-fluid px-4'>
        <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
          <div className='table-responsive pt-3'>
            <table className='table table-bordered table-hover'>
              <thead>
                <tr>
                  <th className='medium color-text-lighter w-30'>Name</th>
                  <th className='medium color-text-lighter w-30'>Description</th>
                  <th className='medium color-text-lighter w-10'>Value ($)</th>
                  <th className='medium color-text-lighter w-20'>Date</th>
                  <th className='text-center medium color-text-lighter w-10'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map(expense => (
                  <tr key={expense.id}>
                    <td className='medium fw-500 w-30'>{expense.name}</td>
                    <td className='medium color-text-lighter w-30'>{expense.description}</td>
                    <td className='medium color-text-lighter w-10'>{expense.value}</td>
                    <td className='medium color-text-lighter w-20'>{formatDate(expense.created_at)}</td>
                    <td className='medium color-text-lighter w-10'>
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <button className='btn hover' style={{ zIndex: 999 }} onClick={(event) => { event.stopPropagation(); openEditExpenseModal(expense) }}>
                          <PencilSquare />
                        </button>
                        <button className='btn text-danger hover' style={{ zIndex: 999 }} onClick={(event) => { event.stopPropagation(); openDeleteExpenseModal(expense) }}>
                          <TrashFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddExpenseModal
        expenses={expenses}
        setExpenses={setExpenses}
        showAddExpenseModal={showAddExpenseModal}
        setShowAddExpenseModal={setShowAddExpenseModal}
        group_id={group_id}
      />

      <EditExpenseModal
        expenses={expenses}
        setExpenses={setExpenses}
        selectedExpense={selectedExpense}
        setSelectedExpense={setSelectedExpense}
        showEditExpenseModal={showEditExpenseModal}
        setShowEditExpenseModal={setShowEditExpenseModal}
      />

      <DeleteExpenseModal
        expenses={expenses}
        setExpenses={setExpenses}
        showDeleteExpenseModal={showDeleteExpenseModal}
        setShowDeleteExpenseModal={setShowDeleteExpenseModal}
        selectedExpense={selectedExpense}
      />

    </div>
  )
}

export default ExpenseGroup