import React from 'react'
import { Github } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

function RepositoryCard({ repository }) {


    return (
        <div className="card hover">
            <Link to={`/repositories/${repository.id}`} className='fw-500 txt-primary mb-2'>
                {repository.name}
            </Link>
            <span className='small text-muted mb-3'>{repository.url}</span>
            <Github className='text-danger h5 mb-0' />
        </div>
    )
}

export default RepositoryCard
