import { apiUrl } from "../../common/api/config";

export const saveBusiness = async (business) => {
    try {
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/businesses', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: business
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.message || 'Failed to save business');
        }

        return responseData;

    } catch (error) {
        throw error;
    }
};

export const updateBusiness = async (business) => {
    try {
        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/businesses/update', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: business
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.message || 'Failed to save business');
        }

        return responseData;

    } catch (error) {
        throw error;
    }
};