import React from 'react'
import styles from './GroupHeader.module.css'
import profileImagePlaceholder from '../../../common/assets/img/profile.svg'
import placeholderProfileImage1 from '../../../common/assets/img/placeholder-profile-img-1.jpeg'
import placeholderProfileImage2 from '../../../common/assets/img/placeholder-profile-img-2.jpg'

function GroupHeader({ clientGroup }) {
    return (
        <div className={styles.GroupHeader}>

            <div>
                <h3 className='bold'>{clientGroup.name}</h3>
                <p>{clientGroup.description}</p>
            </div>

            <div>
                <img src={profileImagePlaceholder} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage1} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage2} className='rounded-circle header-member-pic' alt="" />
                <img src={placeholderProfileImage2} className='rounded-circle header-member-pic' alt="" />
                <span className='p-2'>+2</span>
            </div>

        </div>
    )
}

export default GroupHeader