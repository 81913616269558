import React, { createContext, useContext, useState } from 'react';

// Create the context
export const FocusModeContext = createContext();

// Custom hook to access the context
export const useFocusMode = () => {
    const context = useContext(FocusModeContext);
    if (!context) {
        throw new Error('useFocusMode must be used within a FocusModeProvider');
    }
    return context;
};

// Provider component to wrap your app
export const FocusModeProvider = ({ children }) => {
    const [showFocusMode, setShowFocusMode] = useState(false);

    return (
        <FocusModeContext.Provider value={{ showFocusMode, setShowFocusMode }}>
            {children}
        </FocusModeContext.Provider>
    );
};
