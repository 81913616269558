import React, { useState, useEffect } from 'react'
import { getUserInfo } from '../../common/api/user';
import { getBusinessInfo } from '../../common/api/business';
import NewNotifications from '../components/NewNotifications';
import Calendar from 'react-calendar';
import EmployeePerformance from '../components/EmployeePerformance';
import SkeletonLoader from '../../common/components/animations/SkeletonLoader';

function Home() {

    const [loading, setLoading] = useState(true);
    const [businessInfo, setBusinessInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [value, onChange] = useState(new Date());

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [fetchedUserInfo, fetchedBusinessInfo] = await Promise.all([getUserInfo(), getBusinessInfo()]);
                setUserInfo(fetchedUserInfo);
                setBusinessInfo(fetchedBusinessInfo);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <SkeletonLoader />;
    }


    return (
        <div className="container">

            <div className='pt-3 pb-3'>
                <h4 className='mt-3 bold'>Welcome, {userInfo && userInfo.name.split(" ")[0]}!</h4>
                <span className='medium text-muted'>Here's where you'll view a summary of your business status, priorities, workload, and more.</span>
            </div>

            <div className="row pt-3">
                <div className="col-md-6">
                    <div className="dashboard-card p-3">
                        <h6 className='mb-3'>Employee performance</h6>

                        <EmployeePerformance />

                    </div>

                    <div className="dashboard-card p-3 mt-3">
                        <h6 className='mb-3'>Late tasks</h6>

                        <p className='py-5 text-center'>You have no late tasks 😊</p>

                    </div>

                    <div className="dashboard-card p-3 mt-3">
                        <h6 className='mb-3'>Todays meetings</h6>

                        <p className='py-5 text-center'>You have no meetings for today 😔</p>

                    </div>



                </div>
                <div className="col-md-6">

                    <NewNotifications userInfo={userInfo} />

                    <div className="dashboard-card p-3 mt-3">
                        <h6 className='mb-3'>My calendar</h6>
                        <Calendar onChange={onChange} value={value} className="w-100 border-0 px-4 my-4" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home