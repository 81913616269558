import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from '../../common/components/Button';
import { saveRepository } from '../api/repositories';

function AddRepositoryModal({ showAddRepositoryModal, setShowAddRepositoryModal, repositories, setRepositories }) {

    const [url, setUrl] = useState('');
    const [accessToken, setAccessToken] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async () => {

        setLoading(true);
        setError('');

        const repository = {
            "url": url,
            "access_token": accessToken,
        };

        try {
            const newRepository = await saveRepository(repository);
            setRepositories([newRepository, ...repositories]);
            setShowAddRepositoryModal(false);

            setUrl('');
            setAccessToken('');
            setLoading(false);

            toast.success("Successfully added a new repository!");

        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowAddRepositoryModal(false);
    };

    return (
        <Modal
            show={showAddRepositoryModal} onHide={handleCloseModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='bold h5'>Add repository</Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4'>

                {error && <p className='text-danger small'>{error}</p>} { }

                <label className='pb-2'>Repository URL:</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Your git repository url' value={url} onChange={(e) => setUrl(e.target.value)} />

                <label className='pb-2 mt-3'>Access token (optional, for private repositories):</label>
                <input type="text" className='form-control medium bg-gray-light py-2' placeholder='Your access token' value={accessToken} onChange={(e) => setAccessToken(e.target.value)} />

                <div className='pt-4 d-flex justify-content-end'>
                    <Button variant="primary" size="sm" onClick={handleSubmit} loading={loading} >
                        {loading ? "Fetching repository. This might take a few minutes." : "Save"}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddRepositoryModal