import React, { useState, useEffect } from 'react'
import { getRepositories } from '../api/repositories';
import { Git, Plus } from 'react-bootstrap-icons';
import AddRepositoryModal from '../components/AddRepositoryModal';
import RepositoryCard from '../components/RepositoryCard';

function Repositories() {

    const [repositories, setRepositories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAddRepositoryModal, setShowAddRepositoryModal] = useState(false);


    useEffect(() => {
        const fetchRepos = async () => {
            try {
                const fetchedRepos = await getRepositories();
                setRepositories(fetchedRepos);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchRepos();
    }, []);

    const openAddRepositoryModal = () => {
        setShowAddRepositoryModal(true);
    };

    if (loading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <div className='spinner-border txt-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>

            {!repositories.length && (
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <Git className='h1' style={{ color: 'var(--bs-orange)' }} />
                    <span style={{ fontSize: '12pt' }} className='mt-3 text-muted'>No linked repositories yet</span>
                </div>
            )}

            <div className='container-fluid' style={{ paddingTop: 70 }}>
                <div className='row px-3'>

                    {repositories.map(repository => (
                        <div key={repository.id} className='col-md-4 mb-3'>
                            <RepositoryCard repository={repository} />
                        </div>
                    ))}

                </div>
            </div>


            <button onClick={() => { openAddRepositoryModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            <AddRepositoryModal
                repositories={repositories}
                setRepositories={setRepositories}
                showAddRepositoryModal={showAddRepositoryModal}
                setShowAddRepositoryModal={setShowAddRepositoryModal}
            />

        </>
    )
}

export default Repositories
