import { apiUrl } from '../../common/api/config';

export const getRepositories = async () => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/repositories', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const getCommits = async (repository_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/repositories/' + repository_id + '/commits', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}


export const saveRepository = async (repository) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/repositories', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(repository)
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.message || 'Failed to save repository');
        }

        return responseData;

    } catch (error) {
        throw error;
    }
};


export const deleteRepository = async (repository_id) => {

    try {

        const token = localStorage.getItem('token');

        const response = await fetch(apiUrl + '/items/' + repository_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}