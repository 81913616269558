import Repositories from './pages/Repositories';
import Repository from './pages/Repository';

const repositoryRoutes = [
  {
    path: 'repositories',
    element: <Repositories />,
  },
  {
    path: 'repositories/:id',
    element: <Repository />,
  },
];

export default repositoryRoutes;