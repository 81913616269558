import React, { useState, useEffect, useRef } from 'react';
import { updateTask, removeTask } from '../../api/tasks';
import profileImagePlaceholder from '../../../common/assets/img/profile.svg';
import UpdateAssigneeDropdown from './UpdateAssigneeDropdown';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import RecordComment from '../RecordComment';
import StatusBadge from '../StatusBadge';
import AddSubtaskModal from './AddSubtaskModal';
import Subtasks from '../Subtasks';
import { formatTimeSpent, truncateString } from '../../../common/utils/format_utils';
import AddAttachmentModal from './attachments/AddAttachmentModal';
import Attachments from './attachments/Attachments';
import Cover from './cover/Cover';
import { CheckSquare, Hourglass, ListCheck, Paperclip, TextLeft, Trash, TrashFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import Comments from './comments/Comments';
import FocusButton from '../FocusButton';
import { markTaskNotificationsAsRead } from '../../../notifications/api/notification';

function TaskModal({ showTasksModal, setShowTasksModal, selectedTask, setSelectedTask, tasks, setTasks, people }) {
    const [title, setTitle] = useState(selectedTask.subject || '');
    const [description, setDescription] = useState(selectedTask.description || '');
    const [waitingOn, setWaitingOn] = useState(selectedTask.waiting_on || '');
    const [dueDateTime, setDueDateTime] = useState(selectedTask.due_date ? new Date(selectedTask.due_date) : null);
    const [selectedStatus, setSelectedStatus] = useState(selectedTask.status || '');

    const titleRef = useRef(null);

    useEffect(() => {
        setTitle(selectedTask.subject || '');
        setDescription(selectedTask.description || '');
        setWaitingOn(selectedTask.waiting_on || '');
        if (selectedTask.due_date) {
            const parsedDueDate = new Date(selectedTask.due_date);
            setDueDateTime(parsedDueDate);
        } else {
            setDueDateTime(null);
        }
        setSelectedStatus(selectedTask.status || '');
    }, [selectedTask]);

    useEffect(() => {
        const markNotificationsAsRead = async () => {
            try {

                setSelectedTask(prevTask => {
                    const updatedTask = {
                        ...prevTask,
                        notifications: []
                    };

                    setTasks(prevTasks =>
                        prevTasks.map(task =>
                            task.id === updatedTask.id
                                ? updatedTask
                                : task
                        )

                    );

                    return updatedTask;
                });

                await markTaskNotificationsAsRead(selectedTask.id);
            } catch (error) {
                console.log(error);
            }
        };

        markNotificationsAsRead();

    }, [selectedTask.id]);


    const handleCloseTaskModal = () => {
        setShowTasksModal(false);
    };

    const handleTitleChange = () => {
        setTitle(titleRef.current.value);
    };

    const setCaretPosition = (element, position) => {
        const range = document.createRange();
        const selection = window.getSelection();

        if (element.childNodes.length > 0) {
            range.setStart(element.childNodes[0], Math.min(position, element.textContent.length));
        } else {
            range.setStart(element, Math.min(position, element.textContent.length));
        }

        range.collapse(true);

        selection.removeAllRanges();
        selection.addRange(range);
    };

    const handleBlur = async () => {
        if (title.trim() === '') {
            return;
        }

        const updatedTask = {
            ...selectedTask,
            subject: title,
            description: description,
            waiting_on: waitingOn
        };

        await updateTask(updatedTask);

        setSelectedTask(updatedTask);

        const updatedTasks = tasks.map(task => {
            if (task.id === updatedTask.id) {
                return updatedTask;
            }
            return task;
        });
        setTasks(updatedTasks);
    };

    const changeTaskStatus = async (status) => {
        setSelectedStatus(status);
        const taskIndex = tasks.findIndex((task) => task.id === selectedTask.id);
        if (taskIndex !== -1) {
            const updatedTasks = [...tasks];
            updatedTasks[taskIndex] = {
                ...updatedTasks[taskIndex],
                status: status
            };
            setTasks(updatedTasks);
        }

        const updatedTask = {
            ...selectedTask,
            status: status
        };

        const response = await updateTask(updatedTask);

        if (response.ok) {
            setSelectedTask(updatedTask);
        }

    };

    const handleUpdateDueDate = async (date) => {
        setDueDateTime(date);
        const formattedDueDate = format(date, 'yyyy-MM-dd HH:mm');
        const taskIndex = tasks.findIndex((task) => task.id === selectedTask.id);
        if (taskIndex !== -1) {
            const updatedTasks = [...tasks];
            updatedTasks[taskIndex] = {
                ...updatedTasks[taskIndex],
                due_date: formattedDueDate
            };
            setTasks(updatedTasks);
        }

        const updatedTask = {
            ...selectedTask,
            due_date: formattedDueDate
        };

        const response = await updateTask(updatedTask);

        if (response.ok) {
            setSelectedTask(updatedTask);
        }

    }

    const handleRemoveTask = async () => {
        try {
            setSelectedTask([]);
            setShowTasksModal(false);

            setTasks(prevTasks => {
                if (selectedTask.parent_id) {
                    return prevTasks.map(task => {
                        if (task.id === selectedTask.parent_id) {
                            return {
                                ...task,
                                all_subtasks: task.all_subtasks.filter(subtask => subtask.id !== selectedTask.id)
                            };
                        }
                        return task;
                    });
                } else {
                    return prevTasks.filter(task => task.id !== selectedTask.id);
                }
            });

            await removeTask(selectedTask.id);

        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Modal
            show={showTasksModal} onHide={handleCloseTaskModal}
            backdrop="static" keyboard={false} size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Cover selectedTask={selectedTask} />

            <Modal.Header closeButton className='border-0 pt-4 pb-0'>
                <Modal.Title className='w-100'>
                    <h4 className="bold m-0 w-100 d-flex" onBlur={handleBlur}>
                        <ListCheck className='me-3' style={{ marginTop: '.4rem' }} />
                        <textarea className='h4 bold border-0 w-100 d-flex align-items-center' style={{ resize: 'none' }} ref={titleRef} onBlur={handleBlur} onChange={handleTitleChange} value={title} />
                    </h4>
                </Modal.Title>

            </Modal.Header>

            <Modal.Body className='pb-5'>

                <div className="row">
                    <div className="col-md-8">

                        <div className='d-flex pb-4'>
                            <div className='pe-4'>
                                <span className='small fw-500'>Status</span><br />
                                <StatusBadge status={selectedStatus} />
                            </div>
                            <div className='pe-4'>
                                <span className='small fw-500'>Time spent</span><br />
                                <span className='txt-primary medium fw-500'>{formatTimeSpent(selectedTask?.total_time_spent)}</span>
                            </div>

                        </div>


                        <div className='d-flex align-items-center justify-content-between pb-2'>
                            <span>
                                <TextLeft className='me-2' />
                                Description
                            </span>

                            <RecordComment tasks={tasks} setTasks={setTasks} selectedTask={selectedTask} setSelectedTask={setSelectedTask} description={description} setDescription={setDescription} />
                        </div>

                        <textarea
                            rows={8}
                            className='form-control rounded w-100 py-2 medium'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            onBlur={handleBlur}
                        />

                        <Comments
                            selectedTask={selectedTask}
                            setSelectedTask={setSelectedTask}
                            tasks={tasks}
                            setTasks={setTasks}
                        />

                        <p className='pt-3'>
                            <Hourglass className='me-2' />
                            Waiting on
                        </p>

                        <input
                            className='form-control rounded w-100 py-2 medium'
                            value={waitingOn}
                            onChange={(e) => setWaitingOn(e.target.value)}
                            onBlur={handleBlur}
                        />


                        {selectedTask?.all_subtasks?.length > 0 && (
                            <>
                                <p className='pt-3'>
                                    <CheckSquare className='me-2' />
                                    Subtasks
                                </p>

                                <Subtasks
                                    selectedTask={selectedTask}
                                    setSelectedTask={setSelectedTask}
                                    tasks={tasks}
                                    setTasks={setTasks}
                                />
                            </>
                        )}


                        {selectedTask?.attachments?.length > 0 && (
                            <>
                                <p className='pt-4 mb-0'>
                                    <Paperclip className='me-2' />
                                    Attachments
                                </p>

                                <Attachments
                                    selectedTask={selectedTask}
                                    setSelectedTask={setSelectedTask}
                                    tasks={tasks}
                                    setTasks={setTasks}
                                />
                            </>
                        )}


                    </div>
                    <div className="col-md-4">
                        <div>
                            <p className='pe-2 mb-2 medium fw-500'>Status</p>
                            <Form className='w-100'>
                                <Form.Group controlId="exampleForm.SelectCustom">
                                    <Form.Select custom value={selectedStatus} onChange={(e) => changeTaskStatus(e.target.value)}>
                                        <option value="todo">To do</option>
                                        <option value="in_progress">In progress</option>
                                        <option value="on_hold">On hold</option>
                                        <option value="waiting_for">Waiting for</option>
                                        <option value="done">Done</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className='mt-4'>
                            <p className='pe-2 mb-2 medium fw-500'>Due date</p>
                            <div className='d-flex w-100 flex-column'>
                                <DatePicker
                                    selected={dueDateTime}
                                    onChange={(date) => handleUpdateDueDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    placeholderText='MM-dd-yyyy HH:mm'
                                    className='form-control bg-gray-light py-2 medium'
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />

                            </div>
                        </div>


                        <div className='mt-4'>
                            <p className='pe-2 mb-2 medium fw-500'>Members</p>
                        </div>

                        <div className='d-flex flex-wrap align-items-center text-center'>
                            {selectedTask?.assignees?.map(assignee => (
                                <div className='d-flex flex-column align-items-center mt-3' key={assignee.id}>
                                    <img src={assignee?.person?.avatar ?? profileImagePlaceholder} className='rounded-circle' alt="" style={{ height: 35, width: 35, objectFit: 'cover' }} />
                                    <span className='pt-2 px-2 small'>{truncateString(assignee?.person?.name, 10)}</span>
                                </div>
                            ))}
                            <UpdateAssigneeDropdown people={people} selectedTask={selectedTask} setSelectedTask={setSelectedTask} tasks={tasks} setTasks={setTasks} />

                        </div>

                        <hr className='my-4' />

                        <div style={{ width: 'max-content' }} className='ms-auto d-block'>
                            <div className='d-flex justify-content-end w-100'>
                                <AddSubtaskModal
                                    selectedTask={selectedTask}
                                    setSelectedTask={setSelectedTask}
                                    tasks={tasks}
                                    setTasks={setTasks}
                                />
                            </div>

                            <div className='d-flex justify-content-end w-100 pt-3'>
                                <AddAttachmentModal
                                    selectedTask={selectedTask}
                                    setSelectedTask={setSelectedTask}
                                    tasks={tasks}
                                    setTasks={setTasks}
                                />
                            </div>

                            <div className='d-flex justify-content-end w-100 pt-3'>
                                <FocusButton task={selectedTask} />
                            </div>
                            <hr className='mb-0' />
                            <div className='d-flex justify-content-end w-100 pt-3'>
                                <button onClick={handleRemoveTask} className='btn btn-basic bg-gray border shadow-sm hover medium d-flex align-items-center px-3 w-100'>
                                    <TrashFill className='text-danger me-2' /> Delete task
                                </button>
                            </div>
                        </div>


                    </div>
                </div>

            </Modal.Body >
        </Modal >
    );
}

export default TaskModal;
