import React, { useState, useEffect } from 'react';
import { getPeople } from '../api/people';
import AddPersonModal from '../components/AddPersonModal';
import EditPersonModal from '../components/EditPersonModal';
import DeletePersonModal from '../components/DeletePersonModal';
import { Plus, Trash, TrashFill } from 'react-bootstrap-icons';
import profileImagePlaceholder from '../../common/assets/img/profile.svg';

function People() {

    const [people, setPeople] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState([]);
    const [showAddPersonModal, setShowAddPersonModal] = useState(false);
    const [showEditPersonModal, setShowEditPersonModal] = useState(false);
    const [showDeletePersonModal, setShowDeletePersonModal] = useState(false);

    useEffect(() => {
        const fetchPeople = async () => {
            try {
                const fetchedPeople = await getPeople();
                setPeople(fetchedPeople);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchPeople();
    }, []);

    const openAddPersonModal = () => {
        setShowAddPersonModal(true);
    };


    const openEditPersonModal = (event, person) => {
        event.preventDefault()
        setShowEditPersonModal(true);
        setSelectedPerson(person);
    }

    const openDeletePersonModal = (event, person) => {
        event.preventDefault()
        setShowDeletePersonModal(true);
        setSelectedPerson(person);
    }


    return (

        <div>

            <button onClick={() => { openAddPersonModal() }} className='btn btn-dark right-bottom-button rounded px-3 py-2 shadow-lg'>
                <Plus className='h4 m-0' />
            </button>

            <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                <div className='table-responsive pt-3'>
                    <table className='table table-bordered table-hover'>
                        <thead>
                            <tr>
                                <th className='medium color-text-lighter w-30'>Name</th>
                                <th className='medium color-text-lighter w-40'>Comments</th>
                                <th className='medium color-text-lighter text-center w-10'>Avatar</th>
                                <th className='medium color-text-lighter w-10'>Date Created</th>
                                <th className='text-center medium color-text-lighter w-10'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {people.map(person => (
                                <tr key={person.id} onClick={(event) => { openEditPersonModal(event, person) }} className='pointer'>
                                    <td className='medium color-text-lighter w-30'>{person.name}</td>
                                    <td className='medium color-text-lighter w-40'>{person.comments}</td>
                                    <td className='medium color-text-lighter w-10'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div key={person.id}>
                                                <img src={person.avatar ?? profileImagePlaceholder} className='rounded-circle' alt="" style={{ height: 28, width: 28, objectFit: 'cover' }} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className='medium color-text-lighter w-10'>{person.created_at}</td>
                                    <td className='medium color-text-lighter w-10'>
                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                            <div>
                                                <button className='btn text-danger hover' style={{ zIndex: 999 }} onClick={(event) => { event.stopPropagation(); openDeletePersonModal(event, person) }}>
                                                    <TrashFill />
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <AddPersonModal
                people={people}
                setPeople={setPeople}
                showAddPersonModal={showAddPersonModal}
                setShowAddPersonModal={setShowAddPersonModal}
            />

            <EditPersonModal
                people={people}
                setPeople={setPeople}
                selectedPerson={selectedPerson}
                setSelectedPerson={setSelectedPerson}
                showEditPersonModal={showEditPersonModal}
                setShowEditPersonModal={setShowEditPersonModal}
            />

            <DeletePersonModal
                people={people}
                setPeople={setPeople}
                selectedPerson={selectedPerson}
                showDeletePersonModal={showDeletePersonModal}
                setShowDeletePersonModal={setShowDeletePersonModal}
            />

        </div >
    )
}

export default People;
