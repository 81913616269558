import React, { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from '../components/sidebar/Sidebar';
import Header from '../components/header/Header';
import { getUserInfo } from '../api/user';
import { getMyBusinesses } from '../api/business';
import { Git } from 'react-bootstrap-icons';
import FocusWindowWeb from '../../focus/components/FocusWindowWeb';
import { useFocusMode } from '../contexts/FocusContext';

const pageTitles = {
    "/": "Dashboard",
    "/contacts": "Contacts",
    "/leads": "Leads",
    "/home": "Home",
    "/calendar": "Calendar",
    "/availability": "Availability",
    "/people": "People",
    "/products": "Products",
    "/account": "Account",
    "/team": "Team",
    "/clients/groups": "Client Groups",
    "/workers/groups": "Worker Groups",
};

function AppLayout() {

    const { showFocusMode, setShowFocusMode } = useFocusMode();

    const location = useLocation();
    let pageTitle = pageTitles[location.pathname];

    if (!pageTitle) {
        if (location.pathname.startsWith("/tasks")) {
            pageTitle = "Tasks";
        }
        else if (location.pathname.startsWith("/clients/")) {
            pageTitle = "Clients";
        }
        else if (location.pathname.startsWith("/workers/")) {
            pageTitle = "Workers";
        }
        else if (location.pathname.startsWith("/expenses/")) {
            pageTitle = "Expenses";
        }
        else if (location.pathname.startsWith("/revenues/")) {
            pageTitle = "Revenues";
        }
        else if (location.pathname.startsWith("/products")) {
            pageTitle = "Products";
        }
        else if (location.pathname.startsWith("/repositories")) {
            pageTitle = <><Git className='me-1' /> Repositories</>;
        }
        else if (location.pathname.startsWith("/items")) {
            pageTitle = "Inventory";
        }
        else if (location.pathname.startsWith("/event_types")) {
            pageTitle = "Event types";
        }
        else if (location.pathname.startsWith("/notifications")) {
            pageTitle = "Notifications";
        }
        else {
            pageTitle = "";
        }
    }

    const [userInfo, setUserInfo] = useState(null);
    const [myBusinesses, setMyBusinesses] = useState([]);


    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        const fetchMyBusinesses = async () => {
            try {
                const fetchedMyBusinesses = await getMyBusinesses();
                setMyBusinesses(fetchedMyBusinesses);
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        };

        fetchUserInfo();
        fetchMyBusinesses();

        const intervalId = setInterval(fetchUserInfo, 30000);

        return () => clearInterval(intervalId);

    }, []);



    return (
            <>
                <div className="page-content-wrapper">
                    <Sidebar userInfo={userInfo} setUserInfo={setUserInfo} myBusinesses={myBusinesses} setMyBusinesses={setMyBusinesses} />
                    <div className="main-content-wrapper">
                        <Header pageTitle={pageTitle} userInfo={userInfo} setUserInfo={setUserInfo} myBusinesses={myBusinesses} setMyBusinesses={setMyBusinesses} />
                        <div className={`main-container ${(location.pathname.startsWith('/clients') || location.pathname.startsWith('/workers') || location.pathname.startsWith('/products') || location.pathname.startsWith('/items') || location.pathname.startsWith('/expenses') || location.pathname.startsWith('/revenues')) ? 'p-0' : ''}`}>
                            <Outlet />
                        </div>
                    </div>
                </div>
                {showFocusMode && <FocusWindowWeb />}
            </>
    )
}

export default AppLayout