import React from 'react';
import FocusButton from './FocusButton';
import { format } from 'date-fns';
import profileImagePlaceholder from '../../common/assets/img/profile.svg';
import { truncateString } from '../../common/utils/format_utils';
import StatusBadge from './StatusBadge';
import { ArrowRepeat, ArrowRightCircle, PauseCircle, CheckCircleFill, ListColumns, CardText, Quote, JustifyLeft, Calendar, Calendar3, ArrowCounterclockwise, BellFill, Check2Square } from 'react-bootstrap-icons';
import { ReactComponent as TasksSVG } from '../../common/assets/img/vectors/tasks.svg'

const taskStatuses = ['todo', 'in_progress', 'on_hold', 'waiting_for', 'done'];

const TaskRow = ({ task, onFocus, setSelectedTask, setShowTasksModal }) => {
  const handleShowTaskModal = (task) => {
    setSelectedTask(task);
    setShowTasksModal(true);
  };

  const getIcon = () => {
    switch (task.status) {
      case 'todo':
        return <ArrowRepeat className={`me-2 ${task.status === 'done' ? 'txt-success' : 'text-muted'}`} />;
      case 'in_progress':
        return <ArrowRightCircle className={`me-2 ${task.status === 'done' ? 'txt-success' : 'text-muted'}`} />;
      case 'waiting_for':
        return <PauseCircle className={`me-2 ${task.status === 'done' ? 'txt-success' : 'text-muted'}`} />;
      case 'done':
        return <CheckCircleFill className={`me-2 ${task.status === 'done' ? 'txt-success' : 'text-muted'}`} />;
      default:
        return <ArrowRepeat className={`me-2 ${task.status === 'done' ? 'txt-success' : 'text-muted'}`} />;
    }
  };

  if (!task.due_date) {
    var formattedDate = ''
  } else {
    const selectedDate = new Date(task.due_date);
    var formattedDate = format(selectedDate, 'MMMM dd');
  }

  return (
    <tr key={task.id} onClick={() => handleShowTaskModal(task)} className='pointer'>
      <td className='medium w-25 py-3'>
        {getIcon()}
        {task.subject}
        {task?.notifications?.length ? (
          <span className='ps-2'>
            <BellFill className='txt-pink' style={{ fontSize: 14 }} />
          </span>
        ) : ""}
      </td>
      <td className='small color-text-lighter w-25'>{truncateString(task?.description, 32)}</td>
      <td className='medium color-text-lighter w-10'>
        {formattedDate}
      </td>
      <td className='medium color-text-lighter w-15'>
        {task.waiting_on}
      </td>
      <td className='medium color-text-lighter w-10'>
        <div className='d-flex flex-row'>
          {task?.assignees?.map(user => (
            <div className='px-1' key={user.id}>
              <img src={user?.person?.avatar ?? profileImagePlaceholder} className='rounded-circle' loading='lazy' alt="" style={{ height: 28, width: 28, objectFit: 'cover' }} />
            </div>
          ))}
        </div>
      </td>
      <td className='medium color-text-lighter w-10'>
        <StatusBadge status={task.status} />
        {task?.all_subtasks && task?.all_subtasks?.length > 0 ? (
          <span className='ps-2 small'>
            <Check2Square className='me-1' />
            {task?.all_subtasks.filter(subtask => subtask.status === 'done').length}/{task?.all_subtasks?.length}
          </span>
        ) : ''}
      </td>
      <td className='medium color-text-lighter w-5'>
        <FocusButton task={task} variant='icon' />
      </td>
    </tr>
  );
};

const List = ({
  tasks,
  onFocusTask,
  showTasksModal,
  loading,
  setShowTasksModal,
  showAddTaskCard,
  setShowAddTaskCard,
  setSelectedTask,
  selectedTask
}) => {
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
        <div className='spinner-border txt-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  }

  if (!tasks.length) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
        <TasksSVG />
        <h5 style={{ fontSize: '14pt' }} className='mt-3 bold'>You don't have any tasks</h5>
        <span className='text-muted text-center'>Ready to get started? Create your first task now!</span>
      </div>
    );
  }

  return (
    <div className='py-4 px-2'>
      <div className='m-auto d-block w-100'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th className='medium color-text-lighter w-25'>
                <span className='d-flex align-items-center'>
                  <Quote className='me-2' /> Subject
                </span>
              </th>
              <th className='medium color-text-lighter w-25'>
                <span className='d-flex align-items-center'>
                  <JustifyLeft className='me-2' /> Description
                </span>
              </th>
              <th className='medium color-text-lighter w-10'>
                <span className='d-flex align-items-center'>
                  <Calendar3 className='me-2' /> Due Date
                </span>
              </th>
              <th className='medium color-text-lighter w-15'>
                <span className='d-flex align-items-center'>
                  <ArrowCounterclockwise className='me-2' /> Waiting On
                </span>
              </th>
              <th className='medium color-text-lighter w-10'>
                <span className='d-flex align-items-center'>
                  @ Assignees
                </span>
              </th>
              <th className='medium color-text-lighter w-10'>
                <span className='d-flex align-items-center'>
                  <ArrowRightCircle className='me-2' /> Status
                </span>
              </th>
              <th className='medium color-text-lighter w-5'>
                <span className='d-flex align-items-center'>
                  Actions
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {taskStatuses.map((status) => (
              <React.Fragment key={status}>
                {tasks.filter((task) => task.status === status).length > 0 && (
                  <>
                    <h5 className='mt-4 mb-3 bold'>{(status.charAt(0).toUpperCase() + status.slice(1)).replace("_", " ")}</h5>
                    {tasks.filter((task) => task.status === status).map((task) => (
                      <TaskRow key={task.id} task={task} onFocus={onFocusTask} setSelectedTask={setSelectedTask} setShowTasksModal={setShowTasksModal} />
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default List;
