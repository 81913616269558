import React, { useState, useEffect } from 'react'
import { getUserInfo } from '../../common/api/user';
import icon from '../../common/assets/img/logo.png';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { switchBusiness } from '../../common/api/business';

function Notifications() {

    const [userInfo, setUserInfo] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchUserInfo();
    }, []);

    const handleNavigateNotification = async (notification) => {
        await switchBusiness(notification?.business?.id);
        window.location.href = notification.redirect_to ?? '/notifications/' + notification.id;
    }

    return (
        <div className='container-fluid px-4'>
            <ul className="custom-notifications" style={{ height: 'unset !important' }}>
                {userInfo?.notifications?.map(notification => (
                    <li
                        className={`notification-item hover ${!notification.is_read ? 'unread' : ''}`}
                        key={notification.id}
                    >
                        <a onClick={() => { handleNavigateNotification(notification) }} className="pointer d-flex align-items-center">
                            <div className="notification-sender-icon me-3">
                                <img src={notification?.business?.logo ?? icon} alt="Image" className="img-fluid" />
                            </div>
                            <div className='text'>
                                <strong>
                                    {notification.title}
                                </strong>
                                <p className='small pt-1 mb-1'>
                                    {notification.body}
                                </p>
                                <span className='small text-muted mb-0'>
                                    {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}
                                </span>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Notifications