import React, { useState, useEffect } from 'react'
import { ArrowRight, ChevronRight, Copy, PencilSquare, Person, PersonFill, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { getEventTypes } from '../api/event_types';
import EventTypeCard from '../components/EventTypeCard';
import EditEventTypeModal from '../components/EditEventTypeModal';

function EventTypes() {

    const navigate = useNavigate();

    const [eventTypes, setEventTypes] = useState([]);
    const [selectedEventType, setSelectedEventType] = useState([]);
    const [showEditEventTypeModal, setShowEditEventTypeModal] = useState(false);

    const openEditEventTypeModal = (eventType) => {
        setShowEditEventTypeModal(true);
        setSelectedEventType(eventType);
    }

    useEffect(() => {
        const loadEventTypes = async () => {
            try {
                const fetchedEventTypes = await getEventTypes();
                setEventTypes(fetchedEventTypes);
            } catch (error) {
                console.error('Error fetching schedule:', error);
            }
        };

        loadEventTypes();
    }, []);


    return (
        <>
            <h4 className='mt-3 bold'>Create new event type</h4>

            <div className="card col-md-6 mt-4">
                <div className="row">
                    <div className="col-12 hover pointer" onClick={() => navigate('/event_types/new')}>
                        <div className='row p-3 d-flex align-items-center'>
                            <div className='col-2 d-flex align-items-center'>
                                <Person className='txt-primary' />
                                <ArrowRight className='txt-primary' />
                                <PersonFill className='txt-primary' />
                            </div>
                            <div className='col-6 d-flex justify-content-center flex-column'>
                                <h5>One-on-One</h5>
                                <span className='text-muted medium'>One host with one invitee</span>
                            </div>
                            <div className="col-4 d-flex align-items-end justify-content-center flex-column">
                                <ChevronRight />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {eventTypes?.length ? (
                <>
                    <h4 className='mt-5 bold'>Existing event types</h4>

                    <div className="row mt-4">
                        {eventTypes.map(eventType => (
                            <EventTypeCard eventType={eventType} key={eventType.id} openEditEventTypeModal={openEditEventTypeModal} />
                        ))}
                    </div>
                </>
            ) : ''}


            <EditEventTypeModal
                eventTypes={eventTypes}
                setEventTypes={setEventTypes}
                selectedEventType={selectedEventType}
                setSelectedEventType={setSelectedEventType}
                showEditEventTypeModal={showEditEventTypeModal}
                setShowEditEventTypeModal={setShowEditEventTypeModal}
            />

        </>
    )
}

export default EventTypes
