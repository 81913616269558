import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCommits } from '../api/repositories';
import { Calendar, Chat, ChevronDown, Clock, Git, Person, Robot, SignpostSplit } from 'react-bootstrap-icons';
import { format } from 'date-fns';
import { truncateString } from '../../common/utils/format_utils';
import placeholderProfileImage from '../../common/assets/img/profile.svg';

function Repository() {

    const [commits, setCommits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedCommit, setExpandedCommit] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const fetchCommits = async () => {
            try {
                const fetchedCommits = await getCommits(id);
                setCommits(fetchedCommits);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching :', error);
            }
        };

        fetchCommits();
    }, [id]);

    const toggleSummary = (commitId) => {
        setExpandedCommit(expandedCommit === commitId ? null : commitId);
    };

    if (loading) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                <div className='spinner-border txt-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {!commits.length ? (
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <Git className='h1' style={{ color: 'var(--bs-orange)' }} />
                    <span style={{ fontSize: '12pt' }} className='mt-3 text-muted'>No commits made yet</span>
                </div>
            ) :
                <div className='container-fluid px-4'>
                    <div className='m-auto d-block w-100' style={{ maxWidth: 1500, overflowX: 'auto' }}>
                        <div className='table-responsive pt-3'>
                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th className='medium color-text-lighter border-0'>
                                            <div className='d-flex align-items-center'>
                                                <Chat className='me-2' />
                                                Message
                                            </div>
                                        </th>
                                        <th className='medium color-text-lighter border-0'># Commit</th>
                                        <th className='medium color-text-lighter border-0'>
                                            <div className='d-flex align-items-center'>
                                                <SignpostSplit className='me-2' />
                                                Branch
                                            </div>
                                        </th>
                                        <th className='medium color-text-lighter border-0'>
                                            <div className='d-flex align-items-center'>
                                                <Person className='me-2' />
                                                Author
                                            </div>
                                        </th>
                                        <th className='medium color-text-lighter border-0'>
                                            <div className='d-flex align-items-center'>
                                                <Clock className='me-2' />
                                                Time
                                            </div>
                                        </th>
                                        <th className='medium color-text-lighter border-0'>
                                            <div className='d-flex align-items-center'>
                                                <Robot className='me-2' />
                                                Summary
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commits.map(commit => {
                                        const author = JSON.parse(commit.author);
                                        const date = new Date(commit.created_at);
                                        var formattedDate = format(date, 'M/dd/yyyy hh:mm');

                                        return (
                                            <React.Fragment key={commit.id}>
                                                <tr>
                                                    <td className='medium color-text-lighter border-0'>{truncateString(commit.message, 40)}</td>
                                                    <td className='medium color-text-lighter border-0'>
                                                        <a href={commit.url} target='_blank' rel='noreferrer'>
                                                            {truncateString(commit.sha, 18)}
                                                        </a>
                                                    </td>
                                                    <td className='medium color-text-lighter border-0'>
                                                        {commit.branch}
                                                    </td>
                                                    <td className='medium color-text-lighter border-0'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={placeholderProfileImage} className="rounded-circle" style={{ width: 28, height: 28, objectFit: 'cover' }} alt="" />
                                                            <span className='ps-2'>{author.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className='medium color-text-lighter border-0'>{formattedDate}</td>
                                                    <td className='small color-text-lighter border-0 w-10'>
                                                        <button
                                                            className="btn btn-info small"
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            onClick={() => toggleSummary(commit.id)}
                                                        >
                                                            {expandedCommit === commit.id ? 'Hide summary' : 'Show summary'}
                                                            <ChevronDown className={`ms-1 ${expandedCommit === commit.id ? 'rotate-180' : ''}`} />
                                                        </button>
                                                    </td>
                                                </tr>
                                                
                                                {expandedCommit === commit.id && (
                                                    <tr>
                                                        <td colSpan="5" className='border-0'>
                                                            <div className="alert alert-info mt-3">
                                                                <strong>Summary:</strong> {commit.summary || 'No summary available.'}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Repository;