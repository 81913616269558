import React, { useState, useEffect } from 'react';
import Calendar from '../components/Calendar/Calendar';
import Button from '../../common/components/Button';
import { ArrowLeft, ArrowRight, Calendar2 } from 'react-bootstrap-icons';
import EditEventModal from '../components/EditEventModal/EditEventModal';
import { getEvents, storeDraftEvent } from '../api/events';

function MyCalendar() {

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthName = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const fetchedEvents = await getEvents(selectedMonth);
                setEvents(fetchedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [selectedMonth]);

    const handleMonthChange = (direction) => {
        if (direction === 'prev') {
            if (selectedMonth === 1) {
                setSelectedMonth(12);
                setSelectedYear(selectedYear - 1);
            } else {
                setSelectedMonth(selectedMonth - 1);
            }
        } else if (direction === 'next') {
            if (selectedMonth === 12) {
                setSelectedMonth(1);
                setSelectedYear(selectedYear + 1);
            } else {
                setSelectedMonth(selectedMonth + 1);
            }
        }
    };

    const createDraftEvent = async () => {
        const response = await storeDraftEvent();
        setEvents(prevEvents => [...prevEvents, response]);
        setShowModal(true);
        setSelectedEvent(response);
    }

    return (
        <>
            <div className='d-flex mb-3'>
                <Button onClick={() => createDraftEvent()}>
                    <Calendar2 className='me-2' />
                    New event
                </Button>

                <div className='d-flex align-items-center px-3'>
                    <div className='hover' onClick={() => handleMonthChange('prev')}>
                        <ArrowLeft className='pointer' />
                    </div>
                    <h5 className='mb-0 px-2' style={{ fontSize: '12pt' }}>
                        {new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} {selectedYear}
                    </h5>
                    <div className='hover' onClick={() => handleMonthChange('next')}>
                        <ArrowRight className='pointer' />
                    </div>
                </div>
            </div>

            <Calendar
                events={events}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                currentMonthName={currentMonthName}
                setShowModal={setShowModal}
                setSelectedEvent={setSelectedEvent}
            />

            <EditEventModal
                showModal={showModal}
                setShowModal={setShowModal}
                events={events}
                setEvents={setEvents}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
            />
        </>
    );
}

export default MyCalendar;
