import React, { useState } from 'react';
import logo from '../../../modules/common/assets/img/logo.png'
import { useNavigate, Link } from 'react-router-dom';
import googleIcon from '../../../modules/common/assets/img/icons/google.png'
import { useAuth } from '../../common/contexts/AuthContext';
import Button from '../../common/components/Button';
import { toast } from 'react-toastify';

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const response = await login(email, password);
        if (response.success) {
            toast.success('Successfully logged in!');
            navigate('/my-businesses');
        } else {
            setError(response.message);
        }
        setLoading(false);

    };


    return (
        <div className='main-auth-page-container'>

            <div className="px-5 py-5 w-100" style={{ maxWidth: 620 }}>

                <div>
                    <div className='py-3'>
                        <img src={logo} alt="" className='auth-page-logo' />
                    </div>

                    <div className='py-3'>
                        <h3 className='bold'>Log in</h3>
                        <p className='text-muted'>Welcome back! Please enter your details.</p>
                    </div>

                </div>

                {error && <span className='text-danger small'>{error}</span>} { }

                <form onSubmit={handleSubmit}>
                    <div className='py-2'>
                        <label className='mb-2 ms-1 fw-500'>Email <span className='text-danger'>*</span></label>
                        <input type="text" name='email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control py-3' style={{ backgroundColor: '#EBE9F9' }} placeholder='Enter your email' />
                    </div>
                    <div className='py-2'>
                        <label className='mb-2 ms-1 fw-500'>Password <span className='text-danger'>*</span></label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name='password' className='form-control py-3' style={{ backgroundColor: '#EBE9F9' }} placeholder='••••••••' />
                    </div>

                    <div className='d-flex justify-content-between py-3'>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Remember for 14 days
                            </label>
                        </div>
                        <a href="/forgot-password" className='txt-primary fw-500 text-decoration-none'>Forgot password?</a>
                    </div>


                    <div className='py-2 mt-3'>
                        <Button variant="primary" size="lg" type="submit" loading={loading} className="w-100 py-3" >
                            Log In
                        </Button>
                    </div>
                    <div className='py-2'>
                        <button type="submit" className='btn btn-basic border hover w-100 py-3 fw-500 d-flex align-items-center justify-content-center'>
                            <img src={googleIcon} style={{ height: 20 }} alt="google" />
                            <span className='ps-2'>
                                Sign in with Google
                            </span>
                        </button>
                    </div>

                    <div className='d-flex justify-content-center py-4'>
                        <span className='px-1 text-secondary'>Don't have an account?</span>
                        <Link to="/register" className='px-1 txt-primary fw-500 text-decoration-none'>Sign Up</Link>
                    </div>

                </form>

            </div>

        </div>
    )
}
